.app {
    background-color: black;
    min-height: 100vh;
    position: relative;
    overflow-x: hidden;
}

video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh !important;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.overlay {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-wrapper {
    position: relative;
}

.glowing-logo {
    filter: drop-shadow(0 0 8px rgba(75, 248, 255, 0.4))
           drop-shadow(0 0 12px rgba(59, 250, 154, 0.3));
    animation: pulse 4s ease-in-out infinite;
}

@keyframes pulse {
    0% {
        filter: drop-shadow(0 0 8px rgba(75, 248, 255, 0.4))
               drop-shadow(0 0 12px rgba(59, 250, 154, 0.3));
    }
    50% {
        filter: drop-shadow(0 0 12px rgba(75, 248, 255, 0.6))
               drop-shadow(0 0 16px rgba(59, 250, 154, 0.5));
    }
    100% {
        filter: drop-shadow(0 0 8px rgba(75, 248, 255, 0.4))
               drop-shadow(0 0 12px rgba(59, 250, 154, 0.3));
    }
}

.hideScroll::-webkit-scrollbar {
    display: none;
}

.hideScroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
